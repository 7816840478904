// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function json_checkIfValidJsonString(DATA) {
  let value = typeof DATA !== "string" ? JSON.stringify(DATA) : DATA;
  //////
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
  //////
  return typeof value === "object" && value !== null;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["submit-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_submit(EVENTDATA) {
  // Setup...
  const {
    formField_formOfAddress,
    formField_title,
    formField_firstName,
    formField_lastName,
    formField_email,
    /////
    formField_withCompany,
    formField_formOfAddress_company,
    formField_title_company,
    formField_firstName_company,
    formField_lastName_company,
  } = this.ref;
  const { submitRoute } = this.options;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  ////////////////////////////////////////////////////////////////////
  // Call to Kirby route to register sign-up & send e-mail confirm. //
  ////////////////////////////////////////////////////////////////////

  // Prevent default submission event
  EVENTDATA.preventDefault();

  // Config. request...
  const request = new XMLHttpRequest();
  const data = new FormData();

  // Add data...
  data.append("formOfAddress", formField_formOfAddress?.value);
  data.append("title", formField_title?.value);
  data.append("firstname", formField_firstName?.value);
  data.append("lastname", formField_lastName?.value);
  data.append("email", formField_email?.value);
  data.append("withCompany", formField_withCompany?.checked);
  data.append("formOfAddress_company", formField_formOfAddress_company?.value);
  data.append("title_company", formField_title_company?.value);
  data.append("firstname_company", formField_firstName_company?.value);
  data.append("lastname_company", formField_lastName_company?.value);

  // Extract + add confirm options...
  const confirmOptResponses = this.api.get_confirmOptResponses();
  data.append("confirmOptResponses", JSON.stringify(confirmOptResponses));

  // Init. request...
  request.open("POST", submitRoute, true);

  // Config. response hdl...
  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      // Validate response data...
      const response = json_checkIfValidJsonString(request.response) ? JSON.parse(request.response) : request.response;

      // Process response & set notifcation accordingly...
      let notification;
      let resetForm = false;

      if (response) {
        if (response.customStatus === "alreadyRegistered") notification = "alreadyRegistered";
        else {
          if (request.status === 200) {
            notification = "success";
            resetForm = true;
          } else notification = "error";
        }
      }

      // Show notification...
      this.setState({ notification });

      // Reset form...
      if (resetForm) this.resetForm();
    }
  };

  // Send request...
  request.send(data);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
